//Base
@import 'base/settings';
@import 'base/reset';
@import 'base/normalize';
@import 'base/mixins';
@import 'base/fonts';
@import 'base/animations';
@import 'base/blueprint';
@import 'base/base';
@import 'base/messages';
@import 'base/tooltips';

// Blueprint
@import '~/node_modules/@blueprintjs/core/lib/css/blueprint.css';
@import '~/node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~/node_modules/@blueprintjs/popover2/lib/css/blueprint-popover2.css';
// @import 'base/blueprint';

// Editor
@import 'editor/layout';
@import 'editor/left-sidebar';
@import 'editor/toolbar';
@import 'editor/settings-panel';
@import 'editor/model-gallery-panel';
@import 'editor/step-list';
@import 'editor/step-instructions';
@import 'editor/add-step-button';
@import 'editor/player-viewport';
@import 'editor/loading-screen';
@import 'editor/preview';
@import 'editor/save-status-display';

// Widgets
@import 'widgets/annotations';

// Player
@import 'player/layout';
// @import 'player/navigation-panel';

// @Sara -> manager styles are obsolete. We no longer have a manager page inside the editor
// Manager
@import 'manager/manager';
@import 'manager/header';

// Auth
@import 'auth/auth';
